import styled from '@emotion/styled';
import { fromTheme } from '@kyruus/ui-theme';

export const ProviderDrawerWrapperScreenLarge = styled.div`
  position: relative;
  padding: ${fromTheme('spacing_large')};
  & > .loading-overlay {
    position: absolute;
  }
  & > .loading-overlay .spinner-container {
    position: fixed;
    top: 50%;
    left: 30vw;
  }
`;

export const ProviderDrawerCloseBtnWrapperScreenLarge = styled.div`
  padding-bottom: ${fromTheme('spacing_large')};
  text-align: right;

  .closeBtn {
    padding: 0;

    &:hover {
      background: none;
      border-color: transparent;
    }

    &:focus {
      outline: 1px dotted #212121;
      outline: 5px auto Highlight; // Firefox
      outline: 5px auto -webkit-focus-ring-color;
      background: none;
    }
  }
`;

export const ProviderDrawerSortMenuWrapperScreenLarge = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${fromTheme('spacing_large')} 0;
`;

export const ProviderDrawerContentWrapperScreenLarge = styled.div`
  max-height: calc(100vh - 100px); // minus height of close/btn container
  overflow-y: auto;
`;

// small/medium styles below

export const ProviderListWrapperScreenMediumSmall = styled.div`
  margin-left: ${fromTheme('spacing_large')};
  margin-right: ${fromTheme('spacing_large')};
  margin-bottom: ${fromTheme('spacing_large')};
`;

export const SortMenuWrapperScreenMediumSmall = styled.div`
  text-align: center;
  padding-bottom: ${fromTheme('spacing_medium')};
`;
