import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  hours: {
    id: 'location.hours',
    description: 'hours title',
    defaultMessage: 'Hours'
  },
  closed: {
    id: 'location.hours.closed',
    description: 'hours closed',
    defaultMessage: 'Closed'
  },
  findProviderCta: {
    id: 'location.ctaBtn',
    description: 'cta button text',
    defaultMessage: 'Find Providers at This Location'
  },
  bannerNote: {
    id: 'location.bannerNote',
    description: 'banner heading',
    defaultMessage: 'Note:'
  },
  bannerText: {
    id: 'location.bannerText',
    description: 'banner text',
    defaultMessage:
      'Broadway Cardiology Clinic is open in reduced capacity during federal holidays.'
  },
  labelPhone: {
    id: 'location.profile.phone',
    description: 'profile label phone',
    defaultMessage: 'Phone'
  },
  labelWebsite: {
    id: 'location.profile.website',
    description: 'profile label website',
    defaultMessage: 'Website'
  },
  labelEmail: {
    id: 'location.profile.email',
    description: 'profile label email',
    defaultMessage: 'Email'
  },
  labelFax: {
    id: 'location.profile.fax',
    description: 'profile label fax',
    defaultMessage: 'Fax'
  }
});
