import moment from 'moment';

export const getPhone = (contacts) => {
  const phone = contacts.find((item) => item.contact_type === 'phone');
  return phone && phone.value;
};

export const getFax = (contacts) => {
  const fax = contacts.find((item) => item.contact_type === 'fax');
  return fax && fax.value;
};

export const getEmail = (contacts) => {
  const email = contacts.find((item) => item.contact_type === 'email');
  return email && email.value;
};

export const getWebsiteUrl = (urls) => {
  const url = urls.find((url) => url.primary);
  const href = url && url.value;
  if (href) {
    const loc = new URL(url.value);
    return {
      href,
      display: loc.hostname.replace('www.', '')
    };
  }
};

/**
 * Gets a displayable address string for a location
 * @param {object} location The location to get the display address for.
 * @return {string} Display friendly address string
 */
export function getLocationDisplayAddress(location) {
  const street2 = location.street2 ? location.street2 + ', ' : '';
  const suite = location.suite ? location.suite + ', ' : '';
  return `${location.street1}, ${street2}${suite}${location.city}, ${location.state} ${location.zip}`;
}

/**
 * @param {Object} hoursList - list of periods hours comes from API
 * @param {String} day - weekday (i.e Monday, Tuesday, …)
 * @param {String} time - ‘openTime’ or ‘closeTime’
 * output - convert time slots 24 hour format into 12 hour format with AM/PM using moment for that particular passed day.
 */
const getTimeSlotsAvailability = (hoursList, day, time) =>
  hoursList.periods
    .filter((item) => item.openDay === day)
    .map((item) => {
      return moment(item[time], ['hh:mm']).format('hh:mm A');
    });

/**
 * @param {Object} hoursList - list of periods hours comes from API
 * @param {Array} weekdays - ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
 * output - [
 {
    "Monday" : {
      "openTime": ["08:30 AM", "01:00 PM"]
      "closeTime": ["12:00 PM", "05:00 PM"]
    }
  },
 ...
 ]
 */
export const getHoursList = (hoursList, weekdays) => {
  const days = [];
  weekdays.forEach((day) => {
    days.push({
      [day]: {
        openTime: getTimeSlotsAvailability(hoursList, day, 'openTime'),
        closeTime: getTimeSlotsAvailability(hoursList, day, 'closeTime')
      }
    });
  });
  return days;
};
