import styled from '@emotion/styled';

import { fromTheme } from '@kyruus/ui-theme';

export const LoadMoreButtonWrapper = styled.div`
  > button {
    width: 100%;
    padding-top: ${fromTheme('spacing_medium')};
    padding-bottom: ${fromTheme('spacing_medium')};
  }
`;
