import React from 'react';
import Accessible from '@kyruus/react-ikons/Accessible';
import AmericanSignLanguage from '@kyruus/react-ikons/AmericanSignLanguage';
import ValetParking from '@kyruus/react-ikons/ValetParking';
import Parking from '@kyruus/react-ikons/Parking';
import CheckCircleOutline from '@kyruus/react-ikons/CheckCircleOutline';
import Translation from '@kyruus/react-ikons/Translation';
import { StyledIconRow, InfoLabel } from './styles';
import {
  ACCESSIBLE,
  VALET_PARKING,
  TRANSLATION,
  AMERICAN_SIGN_LANGUAGE,
  PARKING
} from './constants';

const AdditionalInfoIcon = ({ type, name, iconSize = '22px' }) => {
  let Icon;
  switch (type) {
    case ACCESSIBLE:
      Icon = <Accessible size={iconSize} />;
      break;
    case VALET_PARKING:
      Icon = <ValetParking size={iconSize} />;
      break;
    case TRANSLATION:
      Icon = <Translation size={iconSize} />;
      break;
    case AMERICAN_SIGN_LANGUAGE:
      Icon = <AmericanSignLanguage size={iconSize} />;
      break;
    case PARKING:
      Icon = <Parking size={iconSize} />;
      break;
    default:
      Icon = <CheckCircleOutline size={iconSize} />;
  }
  return (
    <StyledIconRow data-testid="AccessibilityIcon">
      {Icon}
      <InfoLabel>{name}</InfoLabel>
    </StyledIconRow>
  );
};

export default AdditionalInfoIcon;
