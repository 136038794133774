import styled from '@emotion/styled';
import { fromTheme } from '@kyruus/ui-theme';

export const Shimmer = styled(`div`)`
  border-radius: 2px;
  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }

  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: ${fromTheme('color_background_secondary')};
  background: linear-gradient(
    to right,
    ${fromTheme('color_button_secondary')} 10%,
    ${fromTheme('color_background_disabled_form_input')} 18%,
    ${fromTheme('color_button_secondary')} 33%
  );
  background-size: 1000px 640px;
  height: 100%;
  position: relative;
`;

export const ShimmerText = styled(Shimmer)`
  height: ${(props) => props.height || fromTheme('font_size')};
`;
