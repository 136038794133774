import React from 'react';
import isEmpty from 'lodash/isEmpty';
import ArrowLeft2 from '@kyruus/react-ikons/ArrowLeft2';
import Search from '@kyruus/react-ikons/Search';
import { KyruusFormattedMessage } from '@kyruus/intl';
import { ButtonLinkWithIcon, ButtonWithIcon } from '@kyruus/button';
import querystring from 'querystring';

import { HeroBtn } from './HeroImage/styles';
import HeroImage from './HeroImage';
import { messages } from './messages';
import { getHeroImage } from './HeroImage/utils';

const getBackToSearchUrl = (currentQuery) => {
  let backToSearchResults = null;
  if (!isEmpty(currentQuery)) {
    backToSearchResults = `/search?${querystring.stringify(currentQuery)}`;
  }
  return backToSearchResults;
};

const HeroImageComponent = ({
  loading,
  media = [],
  history,
  log,
  currentQuery
}) => {
  const searchQuery = getBackToSearchUrl(currentQuery);
  return (
    <HeroImage
      loading={loading}
      imgUrl={getHeroImage(media)}
      imgUrlSmall={getHeroImage(media)}
    >
      <div>
        {searchQuery ? (
          <HeroBtn>
            <ButtonWithIcon
              active={true}
              mode="ghost"
              icon={<ArrowLeft2 size="11px" />}
              iconAlign="left"
              data-testid="BackToSearch"
              onClick={() => {
                log('user_action.location_page.back_to_search');

                history.push(searchQuery);
              }}
            >
              <KyruusFormattedMessage {...messages.backToSearchResults} />
            </ButtonWithIcon>
          </HeroBtn>
        ) : null}
        <HeroBtn>
          <ButtonLinkWithIcon
            mode="ghost"
            href="/"
            active={true}
            icon={<Search size="12px" />}
            iconAlign="left"
          >
            <KyruusFormattedMessage {...messages.startNewSearch} />
          </ButtonLinkWithIcon>
        </HeroBtn>
      </div>
    </HeroImage>
  );
};

export default HeroImageComponent;
