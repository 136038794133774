import React from 'react';
import { string, bool } from 'prop-types';
import { Container, Image, HeroImageContent } from './styles';
import LoadingSkeleton from './loading-skeleton';

export default function HeroImage({ imgUrl, imgUrlSmall, loading, ...props }) {
  return (
    <Container data-testid="HeroImageContainer">
      {loading ? (
        <LoadingSkeleton />
      ) : (
        <Image
          data-testid="HeroImage"
          imgUrl={imgUrl}
          imgUrlSmall={imgUrlSmall}
        />
      )}
      <div className="container">
        <div className="row">
          <HeroImageContent>{!loading && props.children}</HeroImageContent>
        </div>
      </div>
    </Container>
  );
}

const propTypes = {
  imgUrl: string,
  imgUrlSmall: string,
  loading: bool
};

const defaultProps = {
  imgUrl: '',
  imgUrlSmall: '',
  loading: false
};

HeroImage.propTypes = propTypes;
HeroImage.defaultProps = defaultProps;
