import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import DashIcon from '@kyruus/react-ikons/Remove';

import { HeaderContent, Handle, HeaderText, SheetContent } from './styles';

function BottomSheet({ headerText, handleClose, isOpen, children }) {
  const useStyles = makeStyles({
    root: {
      '& .MuiPaper-root': {
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15
      }
    }
  });

  const classes = useStyles();

  return (
    <SwipeableDrawer
      className={classes.root}
      anchor="bottom"
      // The SwipeableDrawer's documentation recommends enabling/disabling certain props if the user is on iOS:
      // "iOS is hosted on high-end devices. The backdrop transition can be enabled without dropping frames. The performance will be good enough."

      // "iOS has a 'swipe to go back' feature that interferes with the discovery feature, so discovery has to be disabled."
      // https://material-ui.com/components/drawers/#swipeable

      // For MVP, disable both props
      disableBackdropTransition={true}
      disableDiscovery={true}
      open={isOpen}
      // onOpen & onClose are required props
      // pass a noop to avoid PropTypes warning
      onOpen={() => {}}
      onClose={handleClose}
      onBackdropClick={handleClose}
      data-testid="BottomSheet"
    >
      <Handle>
        <DashIcon size="24px" />
      </Handle>
      {headerText && (
        <HeaderContent>
          <HeaderText>{headerText}</HeaderText>
        </HeaderContent>
      )}
      <SheetContent>{children}</SheetContent>
    </SwipeableDrawer>
  );
}

BottomSheet.propTypes = {
  headerText: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  children: PropTypes.node
};

export default BottomSheet;
