import styled from '@emotion/styled';
import { fromTheme } from '@kyruus/ui-theme';

export const LocationPage = styled('div')`
  background-color: ${fromTheme('color_background_secondary')};
  padding-bottom: 20px;
  min-height: 100vh;
  * {
    box-sizing: border-box;
  }
`;

export const MainContentWrapper = styled('div')`
  display: block;
  align-items: flex-start;
  flex-wrap: wrap;
  @media (min-width: ${fromTheme('screen_medium')}) {
    display: flex;
    flex-wrap: nowrap;
  }
`;

export const LocationDetailsCol = styled('div')`
  position: relative;
  margin-top: -${fromTheme('spacing_medium')};
  margin-bottom: ${fromTheme('spacing_medium')};
  flex-basis: 100%;
  flex-shrink: 0;
  @media (min-width: ${fromTheme('screen_medium')}) {
    margin-top: -${fromTheme('spacing_xlarge')};
    margin-bottom: ${fromTheme('spacing_xlarge')};
    flex-basis: auto;
    margin-right: ${fromTheme('spacing_medium')};
  }
`;

export const MainContent = styled('div')`
  flex-basis: 100%;
  padding-top: ${fromTheme('spacing_medium')};
`;
