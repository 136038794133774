import React from 'react';
import { injectIntl } from 'react-intl';
import { bool, object, func } from 'prop-types';
import { kyruusFormatMessage } from '@kyruus/intl';
import {
  ProviderInfoListBox,
  ProviderInfoListBoxLoading
} from '@kyruus/provider-components';

import { getConfigProperty } from '../../../../common/config/utils';
import {
  INFO_LIST_TYPES,
  TITLE_MESSAGES,
  NUMBER_OF_COLUMNS,
  TRUNCATE,
  SEARCH_TITLE_MESSAGES
} from './constants';

const InfoListBoxWrapper = ({
  config,
  intl,
  isIdle,
  loading,
  locationData,
  log
}) => {
  const infoListTypesWithData = locationData
    ? INFO_LIST_TYPES.filter(
        (type) => locationData[type] && locationData[type].length
      )
    : [];

  if (loading) {
    return INFO_LIST_TYPES.map((infoListType) => (
      <ProviderInfoListBoxLoading
        key={infoListType}
        id={infoListType}
        title={kyruusFormatMessage(intl, TITLE_MESSAGES[infoListType])}
        numColumns={NUMBER_OF_COLUMNS}
        truncateAt={TRUNCATE}
      />
    ));
  }

  if (isIdle && infoListTypesWithData.length) {
    const v9Profile = getConfigProperty(
      config,
      'darkship_use_profile_searchv9'
    );

    return infoListTypesWithData.map((infoListType) => (
      <ProviderInfoListBox
        key={infoListType}
        id={infoListType}
        cssClasses="provider-insurances"
        title={kyruusFormatMessage(intl, TITLE_MESSAGES[infoListType])}
        itemType={kyruusFormatMessage(intl, TITLE_MESSAGES[infoListType])}
        searchInputTitle={kyruusFormatMessage(
          intl,
          SEARCH_TITLE_MESSAGES[infoListType]
        )}
        items={locationData[infoListType]}
        showClearSearchIcon={true}
        numColumns={NUMBER_OF_COLUMNS}
        log={log}
        truncateAt={TRUNCATE}
        isV9Profile={v9Profile}
        showSearch={true}
      />
    ));
  }

  return null;
};

InfoListBoxWrapper.propTypes = {
  config: object.isRequired,
  isIdle: bool.isRequired,
  loading: bool.isRequired,
  locationData: object,
  log: func.isRequired
};

export default injectIntl(InfoListBoxWrapper);
