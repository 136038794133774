import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  backToSearchResults: {
    id: 'location.hero.backToSearchResults',
    description: 'mloc hero btn - back to search results',
    defaultMessage: 'Back to search results'
  },
  startNewSearch: {
    id: 'location.hero.startNewSearch',
    description: 'mloc hero btn - start a new search',
    defaultMessage: 'Start a new search'
  }
});
