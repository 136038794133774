import React from 'react';
import Helmet from 'react-helmet';
import { object } from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { kyruusFormatMessage } from '@kyruus/intl';

import { getLocationDisplayAddress } from './LocationProfile/utils';
import { getHeroImage } from './HeroImage/utils';
import { buildLocationCanonicalURL } from '../utils/location';
import {
  DEFAULT_SITE_TITLE,
  DEFAULT_LOCALE,
  getConfigProperty
} from 'Common/config';

const messages = defineMessages({
  title: {
    id: 'location.title',
    description: 'Page title for the location',
    defaultMessage: '{name} - {city} - {state} - {locationType}'
  },
  description: {
    id: 'location.description',
    description: 'Page description for the location',
    defaultMessage:
      '{name}. {address} - {locationType} Services: {serviceNames} Specialties: {specialtyNames}'
  }
});

export const Meta = ({ config = {}, locationData, intl }) => {
  const {
    name,
    address,
    specialties = [],
    services = [],
    media,
    location_type
  } = locationData;

  const locale = getConfigProperty(config, 'locale') || DEFAULT_LOCALE;
  const applicationStringTemplates =
    getConfigProperty(config, 'application_string_templates') || {};
  const primaryDomain = getConfigProperty(config, 'primary_domain');
  const host = getConfigProperty(config, 'host');

  const specialtyNames = specialties.map(({ name }) => name).join(', ') || '';
  const serviceNames = services.map(({ name }) => name).join(', ') || '';

  const title = kyruusFormatMessage(intl, messages.title, {
    name,
    city: address.city,
    state: address.state,
    locationType: location_type?.name
  });
  const descriptionMessage = kyruusFormatMessage(intl, messages.description, {
    name,
    address: getLocationDisplayAddress(address),
    locationType: location_type?.name,
    serviceNames,
    specialtyNames
  });
  const url = buildLocationCanonicalURL(primaryDomain || host, locationData);
  const heroImage = getHeroImage(media);

  const descriptionMeta = {
    name: 'description',
    content: descriptionMessage
  };
  const ogTitle = { property: 'og:title', content: title };
  const ogLocale = {
    property: 'og:locale',
    content: locale
  };
  const ogDescription = {
    name: 'description',
    property: 'og:description',
    content: descriptionMessage
  };
  const ogUrl = {
    name: 'url',
    property: 'og:url',
    itemprop: 'url',
    content: url
  };
  const ogType = { property: 'og:type', content: 'profile' };
  const ogImage = heroImage
    ? {
        property: 'og:image',
        content: heroImage
      }
    : {};
  const ogSiteName = {
    property: 'og:site_name',
    content:
      applicationStringTemplates['providerlist.pagedescription.basic'] ||
      DEFAULT_SITE_TITLE
  };

  return (
    <Helmet
      title={title}
      description={descriptionMessage}
      link={[{ rel: 'canonical', href: url }]}
      meta={[
        descriptionMeta,
        ogTitle,
        ogLocale,
        ogDescription,
        ogUrl,
        ogType,
        ogImage,
        ogSiteName
      ]}
    />
  );
};

Meta.propTypes = {
  config: object.isRequired,
  locationData: object.isRequired,
  intl: object.isRequired
};

export default injectIntl(Meta);
