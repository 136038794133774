import { Machine, assign } from 'xstate';
import querystring from 'querystring';

export const states = {
  INITIALIZING: 'INITIALIZING',
  LOADING: 'LOADING',
  IDLE: 'IDLE',
  ERROR: 'ERROR'
};

export const actions = {
  FETCH: 'FETCH'
};

const FETCH_CONFIG = { credentials: 'same-origin' };

const onFetchSuccess = assign({
  mapUrls: (ctx, event) => event.data
});

const onFetchError = assign({
  mapUrls: null,
  error: (ctx, event) => event
});

export const fetchMap = async (context, event) => {
  const {
    data: { locations, sizes, scale, zoom }
  } = event;

  // only include params in the api query if they are supplied
  const params = {
    ids: [...new Set(locations)].join(','),
    sizes: [...new Set(sizes)].join(','),
    ...(scale && { scale }),
    ...(zoom && { zoom }),
    format: 'png'
  };

  const response = await fetch(
    '/google-maps/static-map/mlocs?' + querystring.stringify(params),
    FETCH_CONFIG
  );

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const jsonData = await response.json();
  return jsonData.data;
};

export default Machine(
  {
    id: 'location-map',
    initial: states.INITIALIZING,
    context: {},
    states: {
      [states.INITIALIZING]: {},
      [states.LOADING]: {
        invoke: {
          src: 'fetchMap',
          onDone: {
            target: states.IDLE,
            actions: 'onFetchSuccess'
          },
          onError: {
            target: states.ERROR,
            actions: 'onFetchError'
          }
        }
      },
      [states.IDLE]: {},
      [states.ERROR]: {}
    },
    on: {
      [actions.FETCH]: states.LOADING
    }
  },
  {
    actions: {
      onFetchSuccess,
      onFetchError
    },
    services: {
      fetchMap
    }
  }
);
