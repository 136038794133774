import React from 'react';
import moment from 'moment';
import { KyruusFormattedMessage } from '@kyruus/intl';

import { HoursRowContainer, HoursRow, HoursWrapper } from './styles';
import { getHoursList } from './utils';
import { array, shape } from 'prop-types';
import { messages } from './messages';

export default function HoursList({ hoursList }) {
  const getWeekdays = (format) =>
    Array.apply(null, Array(7)).map(function (_, i) {
      return moment(i, 'e')
        .startOf('week')
        .isoWeekday(i + 1)
        .format(format);
    });

  const weekdays = getWeekdays('dddd');
  const weekdaysShort = getWeekdays('ddd');

  const currentDayIndex = moment().day();
  const currentDay = weekdays[(currentDayIndex || weekdays.length) - 1];

  return (
    <HoursWrapper>
      <KyruusFormattedMessage {...messages.hours} />
      <HoursRowContainer>
        {getHoursList(hoursList, weekdays).map((day, index) => {
          const key = weekdays[index];
          const openTime = day[key]['openTime'];
          const closeTime = day[key]['closeTime'];
          return (
            <HoursRow
              key={key}
              today={currentDay === key}
              disable={
                key === weekdays[weekdays.length - 2] ||
                key === weekdays[weekdays.length - 1]
              }
            >
              <span>{weekdaysShort[index]}</span>
              {openTime.length ? (
                `${openTime[0]} - ${closeTime[closeTime.length - 1]}`
              ) : (
                <KyruusFormattedMessage {...messages.closed} />
              )}
            </HoursRow>
          );
        })}
      </HoursRowContainer>
    </HoursWrapper>
  );
}

const propTypes = {
  hoursList: shape({
    periods: array
  })
};

const defaultProps = {
  hoursList: {
    periods: []
  }
};

HoursList.propTypes = propTypes;
HoursList.defaultProps = defaultProps;
