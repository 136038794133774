import { fromTheme } from '@kyruus/ui-theme';
import styled from '@emotion/styled';

export const Container = styled('div')`
  width: 100%;
  background: ${fromTheme('color_background')};
  padding: ${fromTheme('spacing_large')};
  border-radius: ${fromTheme('border_radius')};
  border: ${fromTheme('border_width')} ${fromTheme('border_style')}
    ${fromTheme('color_border')};
  position: relative;
  font-family: ${fromTheme('font_face')};
  color: ${fromTheme('color_text_secondary')};
  margin-top: ${fromTheme('spacing_medium')};
`;

export const Heading = styled('h2')`
  margin-bottom: ${fromTheme('spacing_medium')};
`;

export const StyledIconRow = styled('div')`
  display: flex;
  align-items: center;
  padding: ${fromTheme('spacing_xsmall')} 0;
  margin-bottom: ${fromTheme('spacing_small')};
`;

export const InfoLabel = styled('span')`
  padding-left: ${fromTheme('spacing_small')};
  padding-top: 2px;
`;

export const LoadingContainer = styled('div')`
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

export const LoadingContent = styled(`div`)`
  width: 100%;
  border-radius: ${fromTheme('border_radius')};
  height: 20px;
  margin-bottom: 16px;
  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }

  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: ${fromTheme('color_background_secondary')};
  background: linear-gradient(
    to right,
    ${fromTheme('color_button_secondary')} 10%,
    ${fromTheme('color_background_disabled_form_input')} 18%,
    ${fromTheme('color_button_secondary')} 33%
  );
  background-size: 1000px 640px;
  position: relative;
`;

export const LoadingHeading = styled(LoadingContent)`
  width: 50%;
`;
