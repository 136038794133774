import { fromTheme } from '@kyruus/ui-theme';
import styled from '@emotion/styled';

export const Container = styled('div')`
  width: 100%;
  height: 180px;
  position: relative;

  @media (min-width: ${fromTheme('screen_medium')}) {
    height: 277px;
  }
`;

export const HeroImageContent = styled('div')`
  position: absolute;
  top: ${fromTheme('spacing_medium')};
  margin-left: ${fromTheme('spacing_medium')};

  @media (min-width: ${fromTheme('screen_medium')}) {
    top: ${fromTheme('spacing_xlarge')};
  }
`;

export const HeroBtn = styled('div')`
  margin-bottom: ${fromTheme('spacing_small')};
`;

export const Image = styled('div')`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('${(props) =>
    props.imgUrlSmall ||
    props.imgUrl ||
    'https://cdn.kyruus.com/img/location-hero-default.jpg'}');

  @media (min-width: ${fromTheme('screen_medium')}) {
    background-image: url('${(props) =>
      props.imgUrl || 'https://cdn.kyruus.com/img/location-hero-default.jpg'}');
  }
`;

export const LoadingContainer = styled('div')`
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

export const LoadingImage = styled(`div`)`
  width: 100%;
  border-radius: ${fromTheme('border_radius')};
  height: 100%;
  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }

  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: ${fromTheme('color_background_secondary')};
  background: linear-gradient(
    to right,
    ${fromTheme('color_button_secondary')} 10%,
    ${fromTheme('color_background_disabled_form_input')} 18%,
    ${fromTheme('color_button_secondary')} 33%
  );
  background-size: 1000px 640px;
  position: relative;
`;
