import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMachine } from '@xstate/react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import querystring from 'querystring';
import Theme from '@kyruus/ui-theme';

import { createLocationSummaryMachine } from '../machines/location-summary/location-summary-machine';
import * as States from '../machines/location-summary/location-summary-states';
import * as Actions from '../machines/location-summary/location-summary-actions';
import { getUpdatedSearch as baseGetUpdatedSearch } from '../behaviors/search-v9/actions';
import { getDefaultCustomerSort } from '../utils/search-common';
import { getProvidersAtLocationUrl } from '../utils/url';

import {
  LocationDetailsCol,
  LocationPage,
  MainContent,
  MainContentWrapper
} from './styles';

import HeroImage from './hero-image';
import LocationProfile from './LocationProfile';
import LocationAdditionalInfo from './AdditionalInfo';
import AboutLocation from './AboutLocation';
import InfoListBoxWrapper from './InfoListBoxWrapper';
import Map from './Map';
import ProviderDrawer from './ProviderDrawer/ProviderDrawer';
import Error from '../error';
import PoweredByLogo from '../shared/powered-by-logo';
import Meta from './meta';

const LocationSummary = (props) => {
  const {
    history,
    locationId,
    locationObject,
    fetchLocation,
    fetchProvidersByLocation,
    config,
    log,
    customerCode,
    productName,
    searchSummary,
    currentQuery,
    match,
    appSettings
  } = props;

  const defaultSort = getDefaultCustomerSort(config);
  const [state, send] = useMachine(
    createLocationSummaryMachine({
      locationId,
      locationObject,
      fetchLocation,
      fetchProvidersByLocation,
      currentQuery: {
        ...currentQuery,
        sort: defaultSort
      }
    })
  );

  const isScreenMediumOrBelow = useMediaQuery(
    `(max-width:${Theme.screen_medium})`
  );

  // trigger new searches when URL changes; this happens when a user selects a new sort option
  useEffect(() => {
    const currentQuery = querystring.parse(
      (history.location.search || '').substring(1)
    );
    send({
      type: Actions.NEW_SEARCH,
      data: {
        currentQuery
      }
    });
  }, [history.location.search, send]);

  const { location: locationData } = state.context;

  const loading = state.matches(States.loading);

  const handleClickFindProviders = () => {
    log('user_action.location_page.find_providers');

    if (config.darkship_display_providers_on_locations_page) {
      send(Actions.DISPLAY_PROVIDERS);
    } else {
      const url = getProvidersAtLocationUrl(
        match.params.id,
        history,
        defaultSort
      );
      history.push(url);
    }
  };

  const handleClickHideProviders = () => send(Actions.HIDE_PROVIDERS);

  const handleClickLoadMore = () => {
    send(Actions.LOAD_MORE);
  };

  const getUpdatedSearch = (modifications) => {
    return baseGetUpdatedSearch(
      config,
      currentQuery,
      history.location,
      modifications
    );
  };

  const pageContent = (
    <LocationPage
      id={locationId}
      itemScope={true}
      itemType="http://schema.org/Place"
      aria-live="polite"
      data-testid="LocationSummary"
    >
      <HeroImage
        loading={loading}
        media={locationData && locationData.media}
        history={history}
        log={log}
        currentQuery={currentQuery}
      />
      <MainContentWrapper className="container">
        <div>
          <LocationDetailsCol>
            <LocationProfile
              loading={loading}
              locationData={locationData}
              onClickFindProviders={handleClickFindProviders}
              log={log}
            />
            <LocationAdditionalInfo
              loading={loading}
              locationData={locationData}
            />
          </LocationDetailsCol>
        </div>
        <MainContent>
          <AboutLocation loading={loading} locationData={locationData} />
          <Map
            loading={loading}
            locationData={locationData}
            appSettings={appSettings}
            config={config}
          />
          <InfoListBoxWrapper
            loading={loading}
            locationData={locationData}
            isIdle={state.matches(States.idle)}
            log={log}
            config={config}
          />
        </MainContent>
      </MainContentWrapper>
    </LocationPage>
  );

  if (state.matches(States.error)) {
    return <Error config={config} />;
  }

  return (
    <React.Fragment>
      {locationData && !loading && (
        <Meta config={config} locationData={locationData} />
      )}
      <div data-testid="LocationSummaryWrapper">
        {pageContent}
        <PoweredByLogo config={config} className="visible-print" />
      </div>
      {state.matches(States.displayingProviders) ? (
        <ProviderDrawer
          onClickHideProviders={handleClickHideProviders}
          onClickLoadMore={handleClickLoadMore}
          isScreenMediumOrBelow={isScreenMediumOrBelow}
          config={config}
          isLoadingProviders={state.matches({
            [States.displayingProviders]: States.loading
          })}
          providers={state.context.providers}
          totalProviders={state.context.totalProviders}
          customerCode={customerCode}
          productName={productName}
          searchSummary={searchSummary}
          currentQuery={currentQuery}
          locationName={locationData.name}
          getUpdatedSearch={getUpdatedSearch}
          locationId={locationId}
          log={log}
          fetchProvidersError={state.context.fetchProvidersError}
          send={send}
        />
      ) : null}
    </React.Fragment>
  );
};

LocationSummary.propTypes = {
  config: PropTypes.object.isRequired,
  locationId: PropTypes.string.isRequired,
  log: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  locationObject: PropTypes.object.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  fetchProvidersByLocation: PropTypes.func.isRequired,
  customerCode: PropTypes.string.isRequired,
  searchSummary: PropTypes.object.isRequired,
  currentQuery: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default LocationSummary;
