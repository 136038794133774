import React from 'react';
import { bool, func, object } from 'prop-types';
import Banner from '@kyruus/banner';
import Button from '@kyruus/button';
import { KyruusFormattedMessage } from '@kyruus/intl';

import {
  Container,
  Heading,
  LocationType,
  LocationAddress,
  FindProviderBtn
} from './styles';
import LoadingSkeleton from './loading-skeleton';
import HoursList from './hours-list';
import Profile from './profile';
import { getLocationDisplayAddress } from './utils';
import { messages } from './messages';

export default function LocationProfile({
  loading,
  locationData = {},
  onClickFindProviders,
  log
}) {
  const {
    name,
    location_type,
    address = {},
    contacts,
    urls,
    hours_of_operation,
    location_description_short
  } = locationData;
  return (
    <Container data-testid="LocationDetails">
      {loading ? (
        <LoadingSkeleton />
      ) : (
        <div data-testid="LocationProfile">
          <Heading>{name}</Heading>
          {location_type?.name != null && (
            <LocationType>{location_type.name}</LocationType>
          )}
          <LocationAddress>
            {getLocationDisplayAddress(address)}
          </LocationAddress>

          <FindProviderBtn>
            <Button
              data-testid="FindProvidersBtn"
              mode="primary"
              onClick={onClickFindProviders}
            >
              <KyruusFormattedMessage {...messages.findProviderCta} />
            </Button>
          </FindProviderBtn>

          <Profile contacts={contacts} urls={urls} log={log} />

          {hours_of_operation && <HoursList hoursList={hours_of_operation} />}

          {location_description_short && (
            <Banner type="info" icon="i">
              <KyruusFormattedMessage
                tagName="strong"
                {...messages.bannerNote}
              />
              <div>{location_description_short}</div>
            </Banner>
          )}
        </div>
      )}
    </Container>
  );
}

const propTypes = {
  loading: bool,
  locationData: object,
  handleClickFindProvidersBtn: func,
  log: func.isRequired
};

const defaultProps = {
  loading: false,
  locationData: {},
  handleClickFindProvidersBtn: () => {}
};

LocationProfile.propTypes = propTypes;
LocationProfile.defaultProps = defaultProps;
