import React from 'react';
import {
  Container,
  LoadingContainer,
  LoadingHeading,
  LoadingContent
} from './styles';

export default function LoadingSkeleton() {
  return (
    <Container>
      <LoadingContainer data-testid="Loading">
        <LoadingHeading />
        <LoadingContent />
        <LoadingContent />
      </LoadingContainer>
    </Container>
  );
}
