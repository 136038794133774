import { messages } from './messages';

export const INSURANCES = 'insurances';
export const SERVICES = 'services';
export const SPECIALTIES = 'specialties';
export const INFO_LIST_TYPES = [INSURANCES, SERVICES, SPECIALTIES];
export const TITLE_MESSAGES = {
  [INSURANCES]: messages.infoListBoxInsurances,
  [SERVICES]: messages.infoListBoxServices,
  [SPECIALTIES]: messages.infoListBoxSpecialties
};
export const SEARCH_TITLE_MESSAGES = {
  [INSURANCES]: messages.infoListBoxInsurancesSearch,
  [SERVICES]: messages.infoListBoxServicesSearch,
  [SPECIALTIES]: messages.infoListBoxSpecialtiesSearch
};
export const NUMBER_OF_COLUMNS = 2;
export const TRUNCATE = 6;
