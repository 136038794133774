import React from 'react';
import {
  Container,
  LoadingContainer,
  LoadingHeading,
  LoadingContent,
  LoadingVideo
} from './styles';

export default function LoadingSkeleton() {
  return (
    <Container>
      <LoadingContainer data-testid="Loading">
        <LoadingHeading />
        <LoadingContent />
        <LoadingContent />
        <LoadingVideo />
      </LoadingContainer>
    </Container>
  );
}
