import React from 'react';
import { object, string, func } from 'prop-types';
import _trimStart from 'lodash/trimStart';
import querystring from 'querystring';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { configSelector } from '../behaviors/configuration';
import {
  fetchLocation,
  getLocationById,
  fetchProvidersByLocation
} from '../behaviors/locations';
import { productNameSelector } from '../behaviors/product-name';
import { pages } from '../tracking/constants';

import LocationSummary from '../location-summary/index';
import { decorateLocationTracking } from './logging-decorators';
import { pageViewEvent } from '../tracking/tracking-utils';
import { getCustomerCode } from '../behaviors/customer-code/selectors';
import { searchSummarySelector } from '../behaviors/search-v9/selectors';
import { settingsSelector } from '../behaviors/settings';

import { getLocationPath } from 'Common/urls/location';

const mapStateToProps = (state, ownProps) => {
  // kyruus apis expect locationId to be uppercase
  const locationId = (ownProps.match.params.id || '').toUpperCase();
  // location prop is already being passed in, so calling the location
  // object that comes back from location service locationObject
  const locationObject = getLocationById(state, locationId);
  const config = configSelector(state);
  const currentQuery = querystring.parse(
    _trimStart(ownProps.location.search, '?')
  );

  const log = (message, event_data = {}) => {
    const additionalTrackingData = decorateLocationTracking({
      state,
      routeMatch: ownProps.match
    });

    ownProps.log(message, { ...event_data, ...additionalTrackingData });
  };

  const customerCode = getCustomerCode(state);

  const searchSummary = searchSummarySelector(state);

  const productName = productNameSelector(state);

  const appSettings = settingsSelector(state);

  return {
    config,
    currentQuery,
    locationId,
    locationObject,
    log,
    customerCode,
    productName,
    searchSummary,
    appSettings
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { fetchLocation, fetchProvidersByLocation },
    dispatch
  );
};

class LocationContainer extends React.Component {
  async serverSideExecute({ req, res, store }) {
    const { locationId, fetchLocation, location } = this.props;
    await fetchLocation(locationId, req);
    const state = store.getState();
    const locationObject = getLocationById(state, locationId);
    processLocationResponse({ location, locationObject, res });
  }

  componentDidMount() {
    const { log } = this.props;

    window.scrollTo(0, 0);

    log(pageViewEvent(pages.LOCATION));
  }

  componentDidUpdate(previousProps) {
    if (this.props.location.pathname !== previousProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return <LocationSummary {...this.props} />;
  }
}

export function processLocationResponse({ location, locationObject, res }) {
  if (!locationObject) {
    res.status(404);
  } else {
    const expectedCanonical = getLocationPath(
      locationObject.name,
      locationObject._entity_id
    );
    if (location.pathname !== expectedCanonical) {
      res.redirect(301, `${expectedCanonical}${location.search || ''}`);
    }
  }
}

LocationContainer.propTypes = {
  currentQuery: object.isRequired,
  config: object.isRequired,
  locationId: string.isRequired,
  locationObject: object,
  customerCode: string.isRequired,
  searchSummary: object.isRequired,
  fetchLocation: func.isRequired,
  fetchProvidersByLocation: func.isRequired,
  log: func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationContainer);
