import React from 'react';
import { KyruusFormattedMessage } from '@kyruus/intl';

import Search from '@kyruus/react-ikons/Search';
import { ButtonWithIcon } from '@kyruus/button';

import { LoadMoreButtonWrapper } from './styles';
import { messages } from './messages';

function LoadMoreButton({ onLoadMoreProvidersClick }) {
  return (
    <LoadMoreButtonWrapper>
      <ButtonWithIcon
        active={true}
        mode="secondary"
        icon={<Search size="11px" />}
        iconAlign="left"
        data-testid="LoadMoreProviders"
        onClick={onLoadMoreProvidersClick}
      >
        <KyruusFormattedMessage {...messages.loadMoreProviders} />
      </ButtonWithIcon>
    </LoadMoreButtonWrapper>
  );
}

export default LoadMoreButton;
