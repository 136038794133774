import { fromTheme } from '@kyruus/ui-theme';
import styled from '@emotion/styled';

export const Container = styled('div')`
  width: 100%;
  background: ${fromTheme('color_background')};
  padding: ${fromTheme('spacing_large')} ${fromTheme('spacing_medium')};
  border-radius: ${fromTheme('spacing_xsmall')};
  border: ${fromTheme('border_width')} ${fromTheme('border_style')}
    ${fromTheme('color_border')};
  position: relative;
  z-index: 1;
  font-family: ${fromTheme('font_face')};
  color: ${fromTheme('color_text_secondary')};

  @media (min-width: 768px) {
    width: 360px;
  }
`;

export const Heading = styled('h2')`
  padding-bottom: ${fromTheme('spacing_medium')};
  color: ${fromTheme('color_text')};
  line-height: ${fromTheme('spacing_large')};
`;

export const LocationType = styled('span')`
  padding-bottom: ${fromTheme('spacing_medium')};
  font-size: ${fromTheme('font_size')};
  display: block;
`;

export const LocationAddress = styled('div')`
  padding-bottom: ${fromTheme('spacing_medium')};
  font-size: ${fromTheme('font_size_heading_5')};
  color: ${fromTheme('color_text')};
`;

export const FindProviderBtn = styled('div')`
  margin: ${fromTheme('spacing_small')} 0;

  button {
    width: 100%;
    border-radius: 4px;
    padding: ${fromTheme('spacing_medium')};
    font-size: ${fromTheme('font_size_heading_5')};
  }
`;

export const ProfileContainer = styled('ul')`
  list-style: none;
  padding-top: ${fromTheme('spacing_medium')};
  text-align: left;
`;

export const ProfileRow = styled('li')`
  display: flex;
  padding-bottom: 12px;

  label {
    width: 64px;
    display: inline-block;
    font-weight: bold;
    padding: 0;
    text-indent: initial;
    flex: 0 0 auto;
    line-height: normal;
    margin-right: ${fromTheme('spacing_small')};
  }
  span {
    word-break: break-all;
    line-height: ${fromTheme('spacing_medium')};

    a {
      color: ${fromTheme('color_link')};
    }
  }
`;

export const HoursWrapper = styled('div')`
  text-align: left;
  margin: ${fromTheme('spacing_small')} 0 0;
  border-top: ${fromTheme('border_width')} ${fromTheme('border_style')}
    ${fromTheme('color_border_disabled')};

  > span {
    color: ${fromTheme('color_text')};
    display: block;
    font-weight: bold;
    padding: ${fromTheme('spacing_medium')} 0;
  }
`;

export const HoursRowContainer = styled('ul')`
  list-style: none;
`;

export const HoursRow = styled('li')`
  color: ${({ disable, today }) =>
    disable && !today
      ? fromTheme('color_text_secondary')
      : fromTheme('color_text')};
  font-weight: ${({ today }) => (today ? 'bold' : 'normal')};
  padding-bottom: ${fromTheme('spacing_small')};
  text-align: left;

  span {
    width: 64px;
    display: inline-block;
    margin-right: ${fromTheme('spacing_small')};
  }
`;

export const LoadingContainer = styled('div')`
  width: 100%;
  height: 100%;
  justify-content: space-between;
  padding: ${fromTheme('spacing_xlarge')} 0;
`;

export const Loader = styled(`div`)`
  width: 100%;
  border-radius: ${fromTheme('border_radius')};
  height: 100%;
  margin: 0 auto 12px;
  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }

  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: ${fromTheme('color_background_secondary')};
  background: linear-gradient(
    to right,
    ${fromTheme('color_button_secondary')} 10%,
    ${fromTheme('color_background_disabled_form_input')} 18%,
    ${fromTheme('color_button_secondary')} 33%
  );
  background-size: 1000px 640px;
  position: relative;
`;

export const Title = styled(Loader)`
  width: 70%;
  height: 34px;
`;

export const Speciality = styled(Loader)`
  width: 40%;
  height: 15px;
`;

export const Address = styled(Loader)`
  width: 80%;
  height: 25px;
`;

export const CtaButton = styled(Loader)`
  height: 50px;
  margin-top: ${fromTheme('spacing_large')};
  margin-bottom: ${fromTheme('spacing_large')};
`;

export const DetailsInfo = styled('div')`
  text-align: center;
`;

export const Label = styled(Loader)`
  width: 38%;
  height: 20px;
  display: inline-block;
  margin-right: 40px;
`;

export const Text = styled(Loader)`
  width: 38%;
  height: 20px;
  display: inline-block;
`;
