import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@kyruus/drawer';

import styled from '@emotion/styled';

const Content = styled.section`
  width: ${(props) => props.width || '50vw'};
  overflow-y: hidden;
  ${(props) => (props.minWidth ? `min-width: ${props.minWidth};` : '')}
`;

function SideSheet({
  dataTestId,
  anchor,
  width,
  minWidth,
  isOpen,
  onClose,
  children
}) {
  return (
    <Drawer
      data-testid={dataTestId}
      anchor={anchor}
      open={isOpen}
      onClose={onClose}
    >
      <Content width={width} minWidth={minWidth}>
        {children}
      </Content>
    </Drawer>
  );
}

SideSheet.propTypes = {
  dataTestId: PropTypes.string,
  anchor: PropTypes.string.isRequired,
  width: PropTypes.string,
  isOpen: PropTypes.bool,
  children: PropTypes.node
};

export default SideSheet;
