import React, { useEffect } from 'react';
import { StaticGmap } from '@kyruus/gmap';
import { AwsMap } from '@kyruus/aws-map';
import { bool, object } from 'prop-types';
import { KyruusFormattedMessage } from '@kyruus/intl';
import { useMachine } from '@xstate/react';

import { Container } from './styles';
import MapUrlMachine, { actions, states } from './machine';
import { Shimmer } from '../../shared/shimmer';
import { messages } from './messages';
import { locationToMapAddress } from './utils';

import {
  SMALL_IMAGE_SIZE,
  MEDIUM_IMAGE_SIZE,
  LARGE_IMAGE_SIZE,
  SMALL_MAP_HEIGHT,
  MEDIUM_MAP_HEIGHT,
  LARGE_MAP_HEIGHT
} from './constants';

const MapLoading = () => {
  return (
    <Container data-testid="MapLoading">
      <Shimmer />
    </Container>
  );
};

const Map = ({ locationData, loading, appSettings, config }) => {
  const { _entity_id: locationId, coordinates: { lat, lng } = {} } =
    locationData || {};

  const [state, send] = useMachine(MapUrlMachine);
  const { mapUrls } = state.context;

  useEffect(() => {
    if (config?.maps_config?.location_service_provider !== 'aws') {
      send({
        type: actions.FETCH,
        data: {
          locations: [locationId],
          scale: window.devicePixelRatio || 1,
          sizes: [SMALL_IMAGE_SIZE, MEDIUM_IMAGE_SIZE, LARGE_IMAGE_SIZE]
        }
      });
    }
  }, [locationId, send, config]);

  if (loading) {
    return <MapLoading />;
  }

  if (!lat || !lng) {
    // don't render map if the MLOC doesn't have location info
    return null;
  }

  if (config?.maps_config?.location_service_provider === 'aws') {
    return (
      <Container data-testid="LocationAwsMap">
        <AwsMap
          awsMapConfig={{
            identityPoolId: appSettings.AWS_MAPS_CREDENTIALS.mapIdentityPoolId,
            mapName: appSettings.AWS_MAPS_CREDENTIALS.mapName,
            region: appSettings.AWS_MAPS_CREDENTIALS.mapRegion
          }}
          locations={[locationData]}
        />
      </Container>
    );
  }

  if (state.matches(states.ERROR)) {
    // don't render map if an error occurred
    return null;
  }

  if (state.matches(states.LOADING) || state.matches(states.INITIALIZING)) {
    return <MapLoading />;
  }

  return (
    <Container data-testid="LocationStaticGmap">
      <KyruusFormattedMessage {...messages.staticMapLabel}>
        {(label) => (
          <StaticGmap
            smallImage={mapUrls[SMALL_IMAGE_SIZE]}
            smallHeight={SMALL_MAP_HEIGHT}
            mediumImage={mapUrls[MEDIUM_IMAGE_SIZE]}
            mediumHeight={MEDIUM_MAP_HEIGHT}
            largeImage={mapUrls[LARGE_IMAGE_SIZE]}
            largeHeight={LARGE_MAP_HEIGHT}
            mapLink={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
              locationToMapAddress(locationData)
            )}`}
            label={label}
          />
        )}
      </KyruusFormattedMessage>
    </Container>
  );
};

Map.propTypes = {
  locationData: object,
  loading: bool
};

export default Map;
