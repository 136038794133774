import React from 'react';
import {
  LoadingContainer,
  Title,
  Speciality,
  Address,
  CtaButton,
  DetailsInfo,
  Label,
  Text
} from './styles';

export default function LoadingSkeleton() {
  return (
    <LoadingContainer data-testid="Loading">
      <Title />
      <Speciality />
      <Address />
      <CtaButton />
      <div>
        <DetailsInfo>
          <Label />
          <Text />
        </DetailsInfo>
        <DetailsInfo>
          <Label />
          <Text />
        </DetailsInfo>
        <DetailsInfo>
          <Label />
          <Text />
        </DetailsInfo>
        <DetailsInfo>
          <Label />
          <Text />
        </DetailsInfo>
        <DetailsInfo>
          <Label />
          <Text />
        </DetailsInfo>
        <DetailsInfo>
          <Label />
          <Text />
        </DetailsInfo>
      </div>
    </LoadingContainer>
  );
}
